<template>
  <v-container fluid fill-height class="ipcDisconnected justify-center">
    <v-sheet
      width="100%"
      max-width="516"
      color="transparent"
      class="position-relative"
    >
      <v-img
        contain
        max-height="570"
        src="@/assets/image/Group 4049@3x.png"
        style="right: 10px"
      ></v-img>
      <v-btn
        v-if="!isIlan"
        color="primary"
        min-width="160"
        min-height="40"
        width="45%"
        height="9%"
        large
        class="text-subtitle-1 position-absolute btn-position"
        @click="toIssueReport"
        ><div class="position-relative btn-text">前往報修</div>
        <v-icon class="align-right-icon position-absolute"
          >mdi-arrow-right</v-icon
        >
      </v-btn>
    </v-sheet>
  </v-container>
</template>

<script>
import { countdownReload } from '@/shared/utils'
import { mapGetters, mapActions } from 'vuex'

export default {
  ...mapGetters({
    isIlan: 'page/isIlan'
  }),
  mounted() {
    // this.startCheckIPC()
  },
  methods: {
    ...mapActions({
      getIPCStatus: 'page/getIPCStatus'
    }),
    toIssueReport() {
      this.$router.push({ name: 'IssueReport' })
    },
    startCheckIPC() {
      console.log('start check IPC disconnected page')
      this.getIPCStatus()
      this.timerCheckIPC = countdownReload(this.getIPCStatus, 300)
    }
  }
}
</script>

<style lang="scss">
.ipcDisconnected {
  height: calc(100vh - 64px);
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.btn-position {
  bottom: 9%;
  left: 64%;
  @media only screen and (max-width: 570px) {
    bottom: 15%;
    right: 2%;
    left: unset;
  }
  .btn-text {
    right: 0.5%;
    @media only screen and (max-width: 570px) {
      right: 3%;
    }
  }
  .align-right-icon {
    right: 2%;
  }
  &:hover {
    .align-right-icon {
      right: 1%;
    }
  }
}
</style>
